function FormSubmit(props) {
  return (

    <button type="submit" disabled={props.loading} className="px-6 py-2 md:py-3 w-full flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-white text-sm font-semibold rounded-md shadow-lg shadow-yellow-500/50 hover:shadow-yellow-500/75 focus:outline-none">
      {props.label}
      <svg className={`${props.loading ? '' : 'hidden'} animate-spin -mr-1 ml-3 h-5 w-5 text-inherit`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
    </button>
  );
}

export default FormSubmit;
