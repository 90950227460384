import { Link } from 'react-router-dom';

function NavItems(props) {
  let items, size, user;

  if (props.items) {
    size = Object.keys(props.items).length;
    items = props.items.map((item, i) => <li key={i} className={(size - 1 !== i) ? 'mr-6' : ''}><Link to={item.link} className="hover:underline decoration-pink-500 text-sm text-light text-right text-yellow-500 hover:text-yellow-600">{item.title}</Link></li>)
  }

  if (props.user) {
    user = <li className="relative ml-6">{props.user}</li>
  }

  return (
    <ul className="flex items-center justify-end">
      {items}
      {user}
    </ul>
  );
}

export default NavItems;
