import axios from 'axios'
import {Logout, Token} from './user'

const instance = axios.create({
  baseURL: process.env.NODE_ENV === `development` ? `https://api.tuke.app/2.0/` : `https://api.tuke.app/2.0/`,
  headers: {
    "Content-Type": "application/json",
  }
})

instance.interceptors.request.use((config) => {
  const token = Token()

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
},
(error) => {
  return Promise.reject(error)
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalConfig = error.config
    if (originalConfig.url !== '/login' && error.response) {
      if (error.response.status === 401) {
        Logout()
      }
    }
    return Promise.reject(error)
  }
)

export default instance
